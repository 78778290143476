import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import { MTabbar } from "@/component"
import { Toast } from 'antd-mobile'
import { post } from "@/service"
import { DelNum } from "@/tool"
import "./styles.less"

const IndexPage = ({ history }) => {
  const [data, setData] = useState(null)
  const [text, setText] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("/app/account/info")
        localStorage.setItem("email", resp?.email)
        Toast.clear()
        setData(resp)
      } catch (error) {
        console.log("error:", error)
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    getData()
  }, [])

  useEffect(() => {
    async function getAnnouncementData() {
      try {
        const resp = await post("/app/message/systemList", {
          page: 1,
          pageSize: 1
        })
        if (!!resp?.content && resp?.content.length > 0) {
          setText(resp?.content[0]?.title)
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    getAnnouncementData()
  }, [])
  
  return (
    !!data ? <div className="assets-page">
    <p className="title">资产</p>
    <div className="banner-div">
      <img src={IMAGE.assetsImage} alt="" className="bg-image" />
      <div className="content-div">
        <p className="est-value">余额</p>
        <p className="value">{`$${DelNum(data.totalBalanceUsd)}`}</p>
      </div>
    </div>
    {
      !!data.accounts && data.accounts.length > 0 && <div className="assets-list">
      {
        data.accounts.map((item, index) => {
          return <div className="assets-item" key={index} onClick={() => history.push(`/token/${item.asset}`)}>
            <img src={item.token.icon} alt="" />
            <div className="name-desc-div">
              <p className="name">{ item.asset }</p>
              <p className="desc">{ item.token.name }</p>
            </div>
            <div className="number-value-div">
              <p className="number">{ DelNum(item.balance) }</p>
              <p className="value">{ `$${DelNum(item.balanceUsd)}` }</p>
            </div>
          </div>
        })
      }
      </div>
    }
    {
      !!text && text.length > 0 && <div className="move-div" onClick={() => history.push("/announcement")}>
        <p className="announcement-text">📣 {text}</p>
      </div>
    }
    <MTabbar selIndex={1} />
  </div> : <div className="assets-page">
    <MTabbar selIndex={1} />
    <p className="title">资产</p>
  </div>
  )
}
export default withRouter(IndexPage)