import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast } from 'antd-mobile'
import { post } from '@/service'
import { IMAGE } from "@/assets"
import { inWechat } from "@/tool"
import './styles.less' 

const SetPasswordPage = ({ history }) => {
  const [value, setValue] = useState("")
  const [confirmValue, setConfirmValue] = useState("")
  const [registerData, setRegisterData] = useState(null)
  const [onFocus, setOnFocus] = useState(false)
  const [onFocusConfirm, setOnFocusConfirm] = useState(false)

  useEffect(() => {
    if (!!history.location && !!history.location.state) {
      const _data = history.location.state
      setRegisterData(_data)
    }
  // eslint-disable-next-line
  }, [])

  function next() {
    const _value = value.trim()
    const _confirmValue = confirmValue.trim()
    var gz = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/
    if (!_value) {
      Toast.show({
        icon: 'fail',
        content: "请输入你的密码"
      })
    } else if (!_confirmValue) {
      Toast.show({
        icon: 'fail',
        content: "请确认你的密码"
      })
    } else if (_value.length < 8) {
      Toast.show({
        icon: 'fail',
        content: "至少 8 个字符"
      })
    } else if (!gz.test(value)) {
      Toast.show({
        icon: 'fail',
        content: "字母和数字的混合",
      })
    } else if (_value !== _confirmValue) {
      Toast.show({
        icon: 'fail',
        content: "两次密码输入需要保持一致",
      })
    } else {
      register(registerData)
    }
  }

  async function register(__registerData) {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      const data = {
        "email": __registerData.userData.account,
        "verify_code": __registerData.verifyCode,
        "password": value,
        "invite_code": __registerData.userData.referralCode,
        "verify_code_tag": "SIGNUP"
      }
      const resp = await post("/app/account/signUp/email", data)
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: "成功"
      })
      pushTo(resp.token)
    } catch (error) {
      console.log("error:", error)
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }

  function pushTo(tokenString) {
    const inwechat = inWechat()
    if (inwechat) {
      history.push("/browser")
    } else {
      localStorage.setItem("token", tokenString)
      history.push("/")
    }
  }
  
  return (
    <div className="set-password-page">
      <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <p className="title">设置你的密码</p>
      <p className="content">至少 8 个字符 — 字母和数字的混合</p>
      <p className="input-name">密码</p>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        <Input clearable value={value} type='password' onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
      </div>
      <p className="input-name">确认密码</p>
      <div className={`input-container ${onFocusConfirm ? 'input-container-active' : ''}`}>
        <Input clearable value={confirmValue} type='password' onFocus={() => setOnFocusConfirm(true)} onBlur={() => setOnFocusConfirm(false)} onChange={val => { setConfirmValue(val) }} className="input-div" />
      </div>
      <Button className="next-button" onClick={() => next()}>继续</Button>
    </div>
  )
}
export default withRouter(SetPasswordPage)