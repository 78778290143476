import React, { useState, useEffect }  from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import { Toast } from 'antd-mobile'
import { post } from "@/service"
import moment from "moment"
import "./styles.less"

const IndexPage = ({ history }) => {
  const {inviteOpenCardUserNum, inviteUserNum} = history.location.state
  const [data, setData] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("/renrenpay/invite/invite_list", {
          page: 1,
          pageSize: 100
        })
        Toast.clear()
        setData(resp.content)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    getData()
  }, [])
  
  return (
    <div className="people-page">
       <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        <p className="title">邀请记录</p>
      </div>
      <div className="data-list">
        <div className="data-item">
          <p className="value">{inviteOpenCardUserNum}</p>
          <p className="key">开卡人数</p>
        </div>
        <div className="data-item">
          <p className="value">{inviteUserNum}</p>
          <p className="key">邀请人数</p>
        </div>
      </div>
      {
        !!data && <div className="record-list">
        {
          data.map((item, index) => {
            return <div key={index} className="record-item">
              <div className="except-time-div">
                <p className="email">{item.email}</p>
                <p className="value" style={{color: item.isOpenCard ? '#10C29F' : '#808080'}}>{ item.isOpenCard ? '已开卡' : '未开卡' }</p>
              </div>
              <p className="time">{ moment(item.time).format("YYYY-MM-DD HH:mm") }</p>
            </div>
          })
        }
        </div>
      }
    </div>
  )
}
export default withRouter(IndexPage)