import React, { useState, useEffect } from "react"
import { Button, Popup, NumberKeyboard } from "antd-mobile"
import { MToken, MContact } from "@/component"
import { DelNum } from "@/tool"
import { IMAGE } from "@/assets"
import "./styles.less"

const INITINPUTAMOUNT = "10"
const IndexComponent = ({
  visible,
  setVisible,
  assets,
  onPay,
  fee=0,
  isApply=false
}) => {
  const [menuIndex, setMenuIndex] = useState(0)
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [selectAmount, setSelectAmount] = useState(INITINPUTAMOUNT)
  const [inputAmount, setInputAmount] = useState(INITINPUTAMOUNT)
  const [amount, setAmount] = useState("")
  const [total, setTotal] = useState("")
  const [selectVisible, setSelectVisible] = useState(false)
  const [assetIndex, setAssetIndex] = useState(0)
  const [asset, setAsset] = useState(null)
  const [emailVisible, setEmailVisible] = useState(false)
  const multiple = isApply && fee === 0 ? 2 : 1

  useEffect(() => {
    if (assetIndex >= assets.length) return
    const _asset = assets[assetIndex]
    setAsset(_asset)
  }, [assets, assetIndex])

  useEffect(() => {
    const _amount = menuIndex < 5 ? selectAmount : inputAmount
    const __amount = !!_amount ? Number(_amount) : 0
    setAmount(__amount)
  }, [menuIndex, selectAmount, inputAmount])

  useEffect(() => {
    if (!asset) return
   const _amount = !amount ? 0 : amount
    const _price = Number(asset.price)
    const _total = Math.ceil(_amount * 100 / _price) / 100
    const __total = _total + fee
    setTotal(__total)
  }, [amount, asset, fee])

  function countDecimalPlaces() {
    const decimalRegex = /\.(\d+)/
    const match = decimalRegex.exec(inputAmount)
    if (match) {
      return match[1].length
    } else {
      return 0
    }
  }

  const onClose = () => {
    setVisible(false)
    setInputAmount(INITINPUTAMOUNT)
    setKeyboardVisible(false)
    setAssetIndex(0)
    setMenuIndex(0)
  }

  return (
    <Popup
      visible={visible}
      onMaskClick={() => onClose()}
      bodyClassName="m_recharge"
    >
      <img src={IMAGE.close_black} alt="" className="m_recharge_close" onClick={() => onClose()} />
      <p className="m_recharge_title">充值</p>
      {
        isApply && (
          <p className="m_recharge_content">{`开卡费5USDT， 并充值${amount || "-"}USD`}</p>
        )
      }
      <div className="m_recharge_menus">
      {
        [INITINPUTAMOUNT, "20", "50", "100", "200", ""].map((item, index) => {
          return (
            <div 
              key={index} 
              className="m_recharge_menu" 
              style={{ borderColor: menuIndex === index ? "#1437FA" : "transparent" }} 
              onClick={() => {
                setMenuIndex(index)
                if (index < 5) {
                  setSelectAmount(item)
                  setKeyboardVisible(false)
                } else {
                  setKeyboardVisible(true)
                }
              }}
            >
            {
              item !== "" ? <>
                <p className="m_recharge_menu_t1">{`${item}USD`}</p>
                <p className="m_recharge_menu_t2">{`${item}USDT`}</p>
              </> : <>
              {
                menuIndex === index ? (
                  <p className="m_recharge_menu_amount">
                    { `${inputAmount}` }
                    <span style={{ visibility: keyboardVisible ? "visible" : "hidden" }}></span>
                  </p>
                ) : (
                  <p className="m_recharge_menu_placehorder">自定义金额</p>
                )
              }
              </>
            }
            </div>
          )
        })
      }
      </div>
      {
        assets.length > 0 && <div className="m_recharge_pay" onClick={() => {
          setKeyboardVisible(false)
          if (isApply) return
          setSelectVisible(true)
        }}>
          <img src={assets[assetIndex].token.icon} alt="" />
          <div className="m_recharge_pay_data" style={{ "--flex": 1 }}>
            <p>支付币种</p>
            <p style={{
              "--fonts": "18px",
              "--color": "#212121",
              "--fontw": 600
            }}>
            {`${total} ${asset?.asset || ""}`}
            </p>
          </div>
          <div className="m_recharge_pay_data">
            <p>余额</p>
            <p>{!asset ? "" :  DelNum(asset.balance) }</p>
          </div>
        </div>
      }
      {
        assets.length > 0 && (
          <Button 
            className="m_recharge_confirm" 
            disabled={
              !amount || 
              !asset ||
              !total ||
              total < asset.minRecharge * multiple || 
              asset.balance < total
            } 
            onClick={() => onPay(amount, asset.asset)}
          >
          { isApply ? `开卡并充值${total}USDT` : `充值${total}USDT` }
          </Button>
        )
      }
      {
        assets.length > 0 && (
          <p className="m_recharge_question" onClick={() => setEmailVisible(true)}>遇到问题?</p>
        )
      }
      <MToken 
        visible={selectVisible}
        setVisible={setSelectVisible}
        assets={assets}
        assetIndex={assetIndex}
        setAssetIndex={setAssetIndex}
      />
      <NumberKeyboard
        visible={keyboardVisible}
        onClose={() => setKeyboardVisible(false)}
        onInput={(value) => setInputAmount(v => countDecimalPlaces() < 3 ? v + value : v)}
        onDelete={() => setInputAmount(v => v.slice(0, v.length - 1))}
        confirmText='完成'
        customKey={inputAmount.includes(".") ? [] : ['.']}
      />
      <MContact 
        visible={emailVisible}
        setVisible={setEmailVisible}
      />
    </Popup>
  )
}

export default IndexComponent