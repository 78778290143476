import React from "react"
import { Popup } from "antd-mobile"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({
  visible,
  setVisible,
  assets,
  assetIndex,
  setAssetIndex,
}) => {

  return (
    <Popup
      visible={visible}
      onMaskClick={() => setVisible(false)}
      bodyClassName="m_token"
    >
      <div className="m_token_list">
      {
        assets.map((item, index) => {
          return <div className="m_token_item" key={index} onClick={() => {
            setAssetIndex(index)
            setVisible(false)
          }}>
            <img src={item.token.icon} alt="" className="m_token_item_icon" />
            <p className="m_token_item_name">{ item.asset }</p>
            {
              assetIndex === index && <img src={IMAGE.select_icon} alt="" className="m_token_item_tag" />
            }
          </div>
        })
      }
      </div>
    </Popup>
  )
}

export default IndexComponent