import cardBg from "./assets/cardBg.png"
import cost1 from "./assets/cost1.png"
import cost2 from "./assets/cost2.png"
import cost3 from "./assets/cost3.png"
import cost4 from "./assets/cost4.png"
import cost5 from "./assets/cost5.png"
import cost6 from "./assets/cost6.png"
import recharge from "./assets/recharge.png"
import info from "./assets/info.png"
import fee from "./assets/fee.png"
import add from "./assets/add.png"
import join from "./assets/join.png"
import share from "./assets/share.png"
import close from "./assets/close.png"
import warn from "./assets/warn.png"

const BASEURL = "https://renrenpay.s3.amazonaws.com"

const addFunds = `${BASEURL}/add-funds.png`
// const cardImage = `${BASEURL}/card-image.png`
const back_black = `${BASEURL}/back-black.png`
const back_white = `${BASEURL}/back-white.png`
const copy = `${BASEURL}/copy.png`
const close_black = `${BASEURL}/close-black.png`
const usd = `${BASEURL}/usd.png`
const usdt = `${BASEURL}/usdt.png`
const assets = `${BASEURL}/assets.png`
const assetsSel = `${BASEURL}/assets-sel.png`
const card = `${BASEURL}/card.png`
const cardSel = `${BASEURL}/card-sel.png`
const invition = `${BASEURL}/invition.png`
const invitionSel = `${BASEURL}/invition-sel.png`
const profile = `${BASEURL}/profile.png`
const profileSel = `${BASEURL}/profile-sel.png`
const assetsImage = `${BASEURL}/assets-image.png`
const deposit = `${BASEURL}/deposit.png`
const withdraw = `${BASEURL}/withdraw.png`
const pay = `${BASEURL}/pay.png`
const swap = `${BASEURL}/swap.png`
const swapIcon = `${BASEURL}/swap-icon.png`
const invitionImage = `${BASEURL}/invition-image.png`
const commission_icon_1 = `${BASEURL}/commission-icon-1.png`
const commission_icon_2 = `${BASEURL}/commission-icon-2.png`
const clickTag = `${BASEURL}/click-tag.png`
const help = `${BASEURL}/help.png`
const share_image = `${BASEURL}/share-bg-image-2.png`
// const join_image = `${BASEURL}/join-image-2.png`
const checked = `${BASEURL}/checked.png`
const brown = `${BASEURL}/brown.png`
const brownLogo = `${BASEURL}/brown-logo.png`
const rechargeLogo = `${BASEURL}/recharge-logo.png`
const customer = `${BASEURL}/customer.png`
const customer2 = `${BASEURL}/customer2.png`
const select_icon = `${BASEURL}/select-icon.png`
const customerCode = `${BASEURL}/customer-code2.jpg`

const IMAGE = {
  cardBg, 
  cost1, 
  cost2, 
  cost3, 
  cost4, 
  cost5, 
  cost6,
  recharge,
  info,
  fee,
  add,
  join,
  share,
  close,
  warn,

  addFunds, back_black, back_white, copy, close_black, usd, usdt, assets, assetsSel, card, cardSel, invition, invitionSel, profile, profileSel, assetsImage, deposit, withdraw, pay, swap, swapIcon, invitionImage, commission_icon_1, commission_icon_2, clickTag, help, share_image, checked, brown, brownLogo, rechargeLogo, customer, select_icon, customer2, customerCode
}

export default IMAGE