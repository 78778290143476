/* eslint-disable no-unused-vars */
import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "@/i18n"
import { 
  PCard,
  PCardInfo,
  PCardCost,
  PCardApply,
  Assets, 
  Token, 
  Recharge, 
  Swap, 
  Invition, 
  InvitionCommission, 
  InvitionPeople, 
  Mine, 
  Join, 
  Register, 
  Verifyaccount,
  Setpassword, 
  Login, 
  Announcement, 
  AnnouncementDetail, 
  Browser, 
  NotFound, 
  ForgetPassword, 
  ChangePassword
} from "@/page"
import { inWechat } from "@/tool"
import "./App.less"

const DomainName = 'https://qpay.money'

const App = () => {
  const inwechat = inWechat()
  const href = window.location.href
  const isDomain = href.startsWith(DomainName)
  const pathname = window.location.pathname
  const isBrower = pathname.startsWith('/browser')
  const isJoin = pathname.startsWith('/join')
  const code = isJoin ? pathname.replace('/join/', '') : ''
  if (!inwechat && (!isDomain || isBrower)) {
    if (isJoin && !!code && code.length > 0) {
      window.location.href = `${DomainName}/join/${code}`
    } else {
      window.location.href = DomainName
    }
  }
  

  return (
    <Router>
      <Switch>
        <Route exact path="/"><PCard /></Route>
        <Route path="/cardinfo"><PCardInfo /></Route>
        <Route path="/cardcost"><PCardCost /></Route>
        <Route path="/cardapply"><PCardApply /></Route>
        <Route path="/assets"><Assets /></Route>
        <Route path="/token/:asset"><Token /></Route>
        <Route path="/recharge"><Recharge /></Route>
        <Route path="/swap"><Swap /></Route>
        <Route path="/mine"><Mine /></Route>
        <Route path="/invition"><Invition /></Route>
        <Route path="/invitionCommission"><InvitionCommission /></Route>
        <Route path="/invitionPeople"><InvitionPeople /></Route>
        <Route path="/join/:code?"><Join /></Route>
        <Route path="/register"><Register /></Route>
        <Route path="/verifyaccount"><Verifyaccount /></Route>
        <Route path="/setpassword"><Setpassword /></Route>
        <Route path="/login"><Login /></Route>
        <Route path="/announcement"><Announcement /></Route>
        <Route path="/announcementDetail"><AnnouncementDetail /></Route>
        <Route path="/browser"><Browser /></Route>
        <Route path="/forgetPassword"><ForgetPassword /></Route>
        <Route path="/changePassword"><ChangePassword /></Route>
        <Route path="/*"><NotFound /></Route>
      </Switch>
    </Router>
  )
}

export default App