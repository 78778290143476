import React from "react"
import { withRouter } from "react-router-dom"
import { MCost } from "@/component"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexPage = ({ history }) => {
  const openCardFee = history.location.state?.openCardFee
  
  return (
    <div className="p_cardcost">
      <div className="p_cardcost_navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className="p_cardcost_name">费用说明</p>
      <MCost openCardFee={openCardFee} />
    </div>
  )
}
export default withRouter(IndexPage)