import React from "react"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({
  openCardFee
}) => {

  const data = [{
    number: `$${openCardFee || "-"}`,
    text: '开卡费'
  },{
    number: '2%+$0.3',
    text: '跨境签账费'
  },{
    number: '$0',
    text: '月费'
  },{
    number: '0%',
    text: '充值费用'
  },{
    number: '$10,000',
    text: '单月充值限额'
  },{
    number: '$1,200',
    text: '单笔消费限额'
  }]

  return (
    <div className="m_cost">
    {
      data.map((item, index) => {
        return <div key={index} className="m_cost_item">
          <img src={IMAGE[`cost${index + 1}`]} alt="" />
          <div>
            <p className="m_cost_number">{item.number}</p>
            <p className="m_cost_text">{item.text}</p>
          </div>
        </div>
      })
    }
    </div>
  )
}

export default IndexComponent