import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import { Popup, Input, Button, Toast } from 'antd-mobile'
import { post } from "@/service"
import { DelNum } from "@/tool"
import "./styles.less"

const IndexPage = ({ history }) => {
  const [swapUSD, setSwapUSD] = useState(true)
  const [inputAmount, setInputAmount] = useState("")
  const [outputAmount, setOutputAmount] = useState("")
  const [visible, setVisible] = useState(false)
  const [usdtData, setUSDT] = useState(null)
  const [usdData, setUSD] = useState(null)
  const [swapParameter, setSwapParameter] = useState(null)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("/app/account/info")
        Toast.clear()
        resp.accounts.forEach(element => {
          if (element.asset === "USDT") {
            setUSDT(element)
          }
          if (element.asset === "USD") {
            setUSD(element)
          }
        })
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    getData()
  }, [])

  useEffect(() => {
    async function getSwapParameter() {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("/app/swap/getSwapParameter", {
          "fromAmount": inputAmount,
          "toAmount": 0,
          "fromToken": swapUSD ? usdtData.asset : usdData.asset,
          "toToken": !swapUSD ? usdtData.asset : usdData.asset,
          "swapType": "INPUT",
          "swapOrderId": ""
        })
        Toast.clear()
        setOutputAmount(resp?.estimatReturnAmount)
        setSwapParameter(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    if (!!inputAmount && parseFloat(inputAmount) > 0 && !!usdtData && !!usdData) {
      getSwapParameter()
    }
  // eslint-disable-next-line
  }, [inputAmount, usdtData, usdData])

  useEffect(() => {
    setOutputAmount("")
    setInputAmount("")
  }, [swapUSD])

  async function toSwap() {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      await post("/app/swap/swap", {
        "fromAmount": swapParameter?.amountIn,
        "toAmount": swapParameter?.estimatReturnAmount,
        "fromToken": swapParameter?.fromToken,
        "toToken": swapParameter?.toToken,
        "swapType": "INPUT",
        "swapOrderId": ""
      })
      setVisible(false)
      setTimeout(() => {
        Toast.clear()
        Toast.show({
          icon: "success",
          content: "兑换成功",
        })
        setTimeout(() => {
          history.goBack()
        }, 1000)
      }, 7000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }
  
  return (
    <div className="swap-page">
      <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
        <p className="title">兑换</p>
      </div>
      {
        !!usdtData ? <div className="body-div">
          <div className="session-div">
            <div className="session-left">
              <div className="info-div">
                <img src={ swapUSD ? usdtData?.token?.icon : usdData?.token?.icon} alt="" />
                <p>{ swapUSD ? usdtData?.asset : usdData?.asset }</p>
              </div>
              <p className="balance">{`余额: ${swapUSD ? DelNum(usdtData?.balance) : DelNum(usdData?.balance)}`}</p>
            </div>
            <div className="session-right">
              <Input value={inputAmount} className="input" onChange={value => setInputAmount(value)}/>
              <p className="max-button" onClick={() => setInputAmount(swapUSD ? Math.floor(usdtData?.balance * 100) / 100 : Math.floor(usdData?.balance * 100) / 100)}>全部</p>
            </div>
          </div>
          <img src={IMAGE.swapIcon} alt="" className="swap-icon" onClick={() => setSwapUSD(!swapUSD)} />
          <div className="session-div">
            <div className="session-left">
              <div className="info-div">
                <img src={ !swapUSD ? usdtData?.token?.icon : usdData?.token?.icon } alt="" />
                <p>{ !swapUSD ? usdtData?.asset : usdData?.asset }</p>
              </div>
            </div>
            <div className="session-right">
              <p className="outputAmount">{ outputAmount.length > 0 ? DelNum(outputAmount) : outputAmount }</p>
            </div>
          </div>
          <Button className="swapButton" disabled={!inputAmount} onClick={() => setVisible(true)}>兑换</Button>
        </div> : <></>
      }
      
      <Popup
        visible={visible}
        onMaskClick={() => setVisible(false)}
        bodyClassName="swap-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => setVisible(false)} />
        <p className="title">确认交易</p>
        <div className="session-div">
          <p className="key">交易</p>
          <p className="value1">{`${DelNum(swapParameter?.amountIn)} ${swapParameter?.fromToken}`}</p>
        </div>
        <div className="session-div">
          <p className="key">收到</p>
          <p className="value2">{`${DelNum(swapParameter?.estimatReturnAmount)} ${swapParameter?.toToken}`}</p>
        </div>
        <Button className="confirm-button" onClick={() => toSwap()}>确定</Button>
      </Popup>
    </div>
  )
}
export default withRouter(IndexPage)