import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import moment from "moment"
import { Button, Toast, Swiper } from "antd-mobile"
import { MTabbar, MCost, MRecharge } from "@/component"
import { post } from "@/service"
import { DelNum } from "@/tool"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexPage = ({ history }) => {
  const [cards, setCards] = useState(null)
  const [cardIndex, setCardIndex] = useState(0) 
  const [cardInfo, setCardInfo] = useState(null)
  const [cardRule, setCardRule] = useState(null)
  const [list, setList] = useState(null)
  const [rechargeVisible, setRechargeVisible] = useState(false)
  const [assets, setAssets] = useState([])
  const [reload, setReload] = useState(false)
  // const _close = !!localStorage.getItem("closeListWarn") ? true : false
  // const [close, setClose] = useState(_close)

  useEffect(() => {
    const getCards = async () => {
      const resp = await post("/card/status")
      return resp
    }
    const getOrder = async () => {
      const resp = await post("/card/applyOrder")
      return resp
    }
    Toast.show({
      icon: "loading",
      content: "加载中...",
      duration: 0
    })
    Promise.all([getCards(), getOrder()]).then(([cardsResp, ordersResp]) => {
      Toast.clear()
      const resp = cardsResp.map(cardResp => {
        const order = ordersResp.find(orderResp => orderResp.cardId === cardResp.id)
        return {
          ...cardResp,
          ...(order && { orderStatus: order.status })
        }
      })
      setCards([...resp])
    }).catch((error) => {
      Toast.show({
        icon: "fail",
        content: error
      })
    })
  }, [reload])

  useEffect(() => {
    if (!cards) return
    if (cards.length === 0) return
    if (cardIndex >= cards.length) {
      setCardIndex(0)
      return
    }
    setCardInfo(cards[cardIndex])
  }, [cards, cardIndex])

  useEffect(() => {
    async function getData() {
      try {
        const resp = await post("card/rules")
        if (!resp) return
        setCardRule(resp)
      } catch (error) {
        Toast.show({
          icon: "fail",
          content: error
        })
      }
    }
    getData()
  }, [])

  useEffect(() => {
    async function getList() {
      try {
        const resp = await post("/card/transaction/transactions", {
          "page": 1,
          "pageCount": 500,
          "cardId": cardInfo.id
        })
        if (!!resp) {
          const _content = !!resp.content && resp.content.length > 0 ? resp.content : []
          setList(_content)
          // const __content = _content.filter(obj => obj.billType !== "Pre-Authorization")
          // const keys = new Set()
          // const ___content = _content.filter(obj => {
          //   const key = obj.amount + obj.desc;
          //   if (!keys.has(key)) {
          //     keys.add(key)
          //     return true
          //   } else if (obj.status === "COMPLETED") {
          //     return true
          //   } else {
          //     return false
          //   }
          // })
          // setList(___content)
        }
      } catch (error) {
        Toast.show({
          icon: "fail",
          content: error
        })
      }
    }
    if (!cardInfo) return
    getList()
  }, [reload, cardInfo])

  useEffect(() => {
    async function getAssets() {
      try {
        Toast.show({
          icon: "loading",
          content: "加载中...",
          duration: 0
        })
        const resp = await post("/app/account/info")
        if (!!resp) {
          const accounts = resp.accounts
          const AccountUSD = accounts.find(account => account.asset === "USD")
          AccountUSD.openCardFee = 5
          AccountUSD.minRecharge = 10
          AccountUSD.price = 1
          const AccountUSDT = accounts.find(account => account.asset === "USDT")
          AccountUSDT.openCardFee = Math.ceil(5 / Number(cardRule.usdtPrice) * 100) / 100
          AccountUSDT.minRecharge = Math.ceil(10 / Number(cardRule.usdtPrice) * 100) / 100
          AccountUSDT.price = Number(cardRule.usdtPrice)
          const _assets = [AccountUSDT]
          setAssets(_assets)
        }
        Toast.clear()
      } catch (error) {
        Toast.show({
          icon: "fail",
          content: error
        })
      }
    }
    if (!cardRule) return
    if (!cardRule.usdtPrice) return
    if (!rechargeVisible) return
    getAssets()
  }, [rechargeVisible, cardRule])

  const onPay = async (amount, asset) => {
    try {
      Toast.show({
        icon: "loading",
        content: "加载中...",
        duration: 0
      })
      await post("/card/transaction/deposit", {
        "amount": amount,
        "cardId": cardInfo.id,
        "asset": asset
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: "充值请求提交成功",
      })
      setRechargeVisible(false)
      setTimeout(() => {
        setReload(!reload)
      }, 1000)
    } catch (error) {
      Toast.show({
        icon: "fail",
        content: error
      })
    }
  }

  return (
    !!cards ? <div className="p_card">
      {
        cards.length > 0 ? (
          <Swiper 
            className="p_card_swiper" 
            loop
            slideSize={80} 
            trackOffset={10} 
            stuckAtBoundary={false} 
            onIndexChange={(_index) => setCardIndex(_index)}
            indicator={(total, current) => (
              <div className="p_card_indicator">
              {
                Array.from({ length: total }).map((_, index) => {
                  return (
                    <div 
                      key={index}
                      className="p_card_indicator_item"
                      style={{ "--bgColor": current === index ? "#1437FA" : "#E6E6E6" }}
                    />
                  )
                })
              }
              </div>
            )}
          >
          {
            cards.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  <div className="p_card_swiper_item"  >
                    <img src={IMAGE.cardBg} alt="" style={{
                      boxShadow: index === cardIndex ? "0px 4px 8px 0px rgba(10, 17, 56, 0.15), 0px 12px 25px 0px rgba(38, 63, 209, 0.25)" : "none"
                    }}/>
                    <p>{`···· ${item.info.number.slice(-4)}`}</p>
                  </div>
                </Swiper.Item>
              )
            })
          }
          </Swiper>
        ) : (
          <Swiper 
            className="p_card_swiper" 
            slideSize={80} 
            trackOffset={10} 
            stuckAtBoundary={false} 
            indicator={() => null}
          >
            <Swiper.Item>
              <div className="p_card_swiper_item">
                <img src={IMAGE.cardBg} alt="" />
              </div>
            </Swiper.Item>
          </Swiper>
        )
      }
      {
        !cardInfo ? (
          <div className="p_card_inactivated">
            <p className="p_card_inactivated_title">全球消费轻松简单</p>
            <MCost openCardFee={cardRule?.openCardFee} />
            <Button 
              className="p_card_inactivated_button"
              onClick={() => history.push("/cardapply")} 
            >开卡</Button>
            <p className="p_card_inactivated_tip">
            { 
              !!cardRule && Number(cardRule.openCardFee) === 0 ? 
                "请确保您的账户余额不少于10USDT" 
              : 
                `开通新卡需充值${cardRule?.openCardFee || "-"}USDT，请确保您的账户余额不少于10USDT` 
            }
            </p>
          </div>
        ) : cardInfo.orderStatus === "PASSED" ? (
          <div className="p_card_activated">
            <p className="p_card_activated_t1">余额</p>
            <p className="p_card_activated_balance">{`$${DelNum(cardInfo.info.amount)}`}</p>
            <div className="p_card_activated_menu">
              <div className="p_card_activated_menu_item" onClick={() => setRechargeVisible(true)}>
                <img src={IMAGE.recharge} alt="" />
                <p>充值</p>
              </div>
              <div className="p_card_activated_menu_item" onClick={() => history.push({
                pathname: "/cardinfo",
                state: cardInfo
              })}>
                <img src={IMAGE.info} alt="" />
                <p>卡信息</p>
              </div>
              <div className="p_card_activated_menu_item" onClick={() => history.push({
                pathname: "/cardcost",
                state: cardRule
              })}>
                <img src={IMAGE.fee} alt="" />
                <p>费用说明</p>
              </div>
              <div className="p_card_activated_menu_item" onClick={() => history.push("/cardapply")}>
                <img src={IMAGE.add} alt="" />
                <p>添加卡</p>
              </div>
            </div>
            {
              !!list && list.length > 0 && <div className="p_card_activated_list">
                <div className="p_card_activated_list_head">
                  <p className="p_card_activated_list_t1">最近的交易</p>
                  {
                    !!cardInfo && (
                      <p className="p_card_activated_list_t2">{`拒付 ${cardInfo.rejectTimes}次 · ${parseFloat(Math.floor(Number(cardInfo?.rejectRate || "0") * 10000) / 100)}%`}</p>
                    )
                  }
                </div>
                {/* {
                  !close && (
                    <div className="p_card_activated_list_warn">
                      <img src={IMAGE.warn} alt="" draggable="false" />
                      <p>拒付次数到达5次且拒付率到20%，卡片将被冻结</p>
                      <img src={IMAGE.close} alt="" draggable="false" onClick={() => {
                        console.log("===")
                        localStorage.setItem("closeListWarn", true)
                        setClose(true)
                      }} />
                    </div>
                  )
                } */}
                {
                  list.map((item, index) => {
                    return <div key={index} className="p_card_activated_list_item">
                      <div className="p_card_activated_list_item_row">
                        <p style={{ "--fonts": "16px", "--color": "#212121", "--fontw": 600 }}>
                          { item.desc }{ item.billCat === "FOREIGN_TRANSACTION_FEE" ? " · Foreign Transaction Fee" : "" }
                        </p>
                        <p style={{ "--fonts": "16px", "--color": parseFloat(item.amount) > 0 ? "#10C29F" : "#212121" }}>{item.amount === "-0" ? "0" : DelNum(item.amount)}</p>
                      </div>
                      <div className="p_card_activated_list_item_row">
                        <p>{ moment(item.time * 1000).format("YYYY-MM-DD HH:mm") }</p>
                        {/* {
                          item.status === "PENDING" && <p>处理中</p>
                        }
                        {
                          item.status === "DECLINED" && <p style={{ "--color": "#FF5151" }}>已拒绝</p>
                        } */}
                        {
                          item.billCat === "PRE_AUTHORIZATION" && <p>进行中</p>
                        }
                        {
                          item.billCat === "REFUND" && <p style={{ "--color": "#FF5151" }}>已拒绝</p>
                        } 
                      </div>
                    </div>
                  })
                }
              </div>
            }
          </div>
        ) : (
          <div className="p_card_inactivated">
            <p className="p_card_inactivated_title">全球消费轻松简单</p>
            <MCost openCardFee={cardRule?.openCardFee} />
            <Button 
              className="p_card_inactivated_button" 
              disabled={ cardInfo.orderStatus === "CREATED" || cardInfo.orderStatus === "REJECTED" }
              onClick={() => history.push("/cardapply")} 
            >{
              cardInfo.orderStatus === "CREATED" ? 
                "审核中..." 
              : cardInfo.status === "REJECTED" ? 
                "已拒绝" 
              : 
                "开卡"
            }</Button>
            <p className="p_card_inactivated_tip">
            { 
              !!cardRule && Number(cardRule.openCardFee) === 0 ? 
                "请确保您的账户余额不少于10USDT" 
              : 
                `开通新卡需充值${cardRule?.openCardFee || "-"}USDT，请确保您的账户余额不少于10USDT` 
            }
            </p>
          </div>
        )
      }
      <MRecharge 
        visible={rechargeVisible}
        setVisible={setRechargeVisible}
        assets={assets}
        onPay={onPay}
      />
      <MTabbar selIndex={0} />
    </div> : <div className="p_card">
        <Swiper 
          className="p_card_swiper" 
          slideSize={80} 
          trackOffset={10} 
          stuckAtBoundary={false} 
          indicator={() => null}
        >
        <Swiper.Item>
          <div className="p_card_swiper_item">
            <img src={IMAGE.cardBg} alt="" />
          </div>
        </Swiper.Item>
      </Swiper>
      <MTabbar selIndex={0} />
    </div>
  )
}
export default withRouter(IndexPage)