import React, {useState, useRef} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast } from 'antd-mobile'
import { post } from '@/service'
import { IMAGE } from "@/assets"
import './styles.less' 

const ForgetPasswordPage = ({ history }) => {
  const [codeValue, setCodeValue] = useState("")
  const [timeOut, setTimeOut] = useState(0)
  const timerID = useRef()
  const [onFocusEmail, setOnFocusEmail] = useState(false)
  const [emailValue, setEmailValue] = useState("")
  const [onFocusCode, setOnFocusCode] = useState(false)
  const [passwordValue, setPasswordValue] = useState("")
  const [confirmValue, setConfirmValue] = useState("")
  const [onFocus, setOnFocusPassword] = useState(false)
  const [onFocusConfirm, setOnFocusConfirm] = useState(false)

  async function toCaptcha() {
    const _emailValue = emailValue.trim()
    if (!_emailValue) {
      Toast.show({
        icon: 'fail',
        content: "请输入邮箱"
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("app/captcha/needCaptcha", {
          email: _emailValue
        })
        Toast.clear()
        const {need} = resp
        const type = "TENCENT"
        if (need) {
          if (type === "TENCENT") {
            initTencentCaptcha(type, _emailValue).show()
          } else {
            window.grecaptcha.ready(function() {
              window.grecaptcha.execute('6LcSBe8pAAAAADI0vzJo_nAt84cOiNGnrmdAuepT', {action: 'submit'}).then(function(token) {
                sendVerifyCode(_emailValue, {
                  captchaType: type,
                  tencentRandstr: null,
                  tencentTicket: null,
                  googleResponse: token
                })
              })
            })
          }
        } else {
          sendVerifyCode(_emailValue, {
            captchaType: type,
            tencentRandstr: null,
            tencentTicket: null,
            googleResponse: null
          })
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
  }

  function initTencentCaptcha(type, account) {
    return new window.TencentCaptcha('2038742797', function(res) {
      const {ticket, randstr} = res
      if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
        sendVerifyCode(account, {
          captchaType: type,
          tencentRandstr: randstr,
          tencentTicket: ticket,
          googleResponse: null
        })
      }
    })
  }

  async function sendVerifyCode(account, result) {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      result["email"] = account
      result["tag"] = "SET_PASSWORD"
      await post("/app/account/sendEmailVerifyCodeV3", result)
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: "成功"
      })
      countDown()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }

  function countDown() {
    if (!!timerID.current) {
      clearInterval(timerID.current)
    }
    let timeOut = 60
    setTimeOut(timeOut)
    timerID.current = setInterval(() => {
      if (timeOut > 0) {
        timeOut = timeOut - 1
        setTimeOut(timeOut)
      } else {
        clearInterval(timerID.current)
      }
    }, 1000)
  }

  async function next() {
    const _emailValue = emailValue.trim()
    const _codeValue = codeValue.trim()
    const _passwordValue = passwordValue.trim()
    const _confirmValue = confirmValue.trim()
    var gz = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/
    if (!_emailValue) {
      Toast.show({
        icon: 'fail',
        content: "请输入邮箱"
      })
    } else if (!_codeValue) {
      Toast.show({
        icon: 'fail',
        content: "请输入邮箱验证码"
      })
    } else if (!_passwordValue) {
      Toast.show({
        icon: 'fail',
        content: "请输入你的密码"
      })
    } else if (!_confirmValue) {
      Toast.show({
        icon: 'fail',
        content: "请确认你的密码"
      })
    } else if (_passwordValue.length < 8) {
      Toast.show({
        icon: 'fail',
        content: "至少 8 个字符"
      })
    } else if (!gz.test(_passwordValue)) {
      Toast.show({
        icon: 'fail',
        content: "字母和数字的混合",
      })
    } else if (_passwordValue !== _confirmValue) {
      Toast.show({
        icon: 'fail',
        content: "两次密码输入需要保持一致",
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        await post("/app/account/setPassword/email", {
          "verify_code": _codeValue,
          "email": _emailValue,
          "password": _passwordValue,
          "verify_code_tag": "SET_PASSWORD"
        })
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: "成功"
        })
        setTimeOut(() => {
          history.goBack()
        }, 1000)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      } 
    }
  }
  
  return (
    <>
    {
      <div className="verify-account-page">
        <div className="nav-bar" >
          <img src={IMAGE.back_black} alt="" className="back-button" onClick={() => history.goBack()} />
        </div>
        <p className="title">忘记密码</p>
        <p className="content">密码至少 8 个字符 — 字母和数字的混合</p>
        <p className="input-name">邮箱</p>
        <div className={`input-container ${onFocusEmail ? 'input-container-active' : ''}`}>
          <Input clearable value={emailValue} onFocus={() => setOnFocusEmail(true)} onBlur={() => setOnFocusEmail(false)} onChange={val => { setEmailValue(val) }} className="input-div" />
        </div>
        <p className="input-name">邮箱验证码</p>
        <div className={`input-container ${onFocusCode ? 'input-container-active' : ''}`}>
          <Input clearable value={codeValue} onFocus={() => setOnFocusCode(true)} onBlur={() => setOnFocusCode(false)} onChange={val => { setCodeValue(val) }} className="input-div" />
          {
            timeOut === 0 ? <p className="resend-button" onClick={() => toCaptcha()}>发送</p> : <p className="resend-button resend-button-disable">{`重新发送 ${timeOut}s`}</p>
          }
        </div>
        <p className="input-name">密码</p>
        <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
          <Input clearable value={passwordValue} type='password' onFocus={() => setOnFocusPassword(true)} onBlur={() => setOnFocusPassword(false)} onChange={val => { setPasswordValue(val) }} className="input-div" />
        </div>
        <p className="input-name">确认密码</p>
        <div className={`input-container ${onFocusConfirm ? 'input-container-active' : ''}`}>
          <Input clearable value={confirmValue} type='password' onFocus={() => setOnFocusConfirm(true)} onBlur={() => setOnFocusConfirm(false)} onChange={val => { setConfirmValue(val) }} className="input-div" />
        </div>
        <Button className="send-button" onClick={() => next()}>确认</Button>
      </div>
    }
    </>
  )
}
export default withRouter(ForgetPasswordPage)