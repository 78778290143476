import React, {useState} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast } from 'antd-mobile'
import { post } from '@/service'
import { IMAGE } from "@/assets"
import './styles.less' 

const ChangePasswordPage = ({ history }) => {
  const [onFocusCurrent, setOnFocusCurrent] = useState(false)
  const [currentValue, setCurrentValue] = useState("")
  const [passwordValue, setPasswordValue] = useState("")
  const [confirmValue, setConfirmValue] = useState("")
  const [onFocus, setOnFocusPassword] = useState(false)
  const [onFocusConfirm, setOnFocusConfirm] = useState(false)
  
  async function next() {
    const _currentValue = currentValue.trim()
    const _passwordValue = passwordValue.trim()
    const _confirmValue = confirmValue.trim()
    var gz = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/
    if (!_currentValue) {
      Toast.show({
        icon: 'fail',
        content: "请输入当前密码"
      })
    } else if (!_passwordValue) {
      Toast.show({
        icon: 'fail',
        content: "请输入新密码"
      })
    } else if (!_confirmValue) {
      Toast.show({
        icon: 'fail',
        content: "请确认你的密码"
      })
    } else if (_passwordValue.length < 8) {
      Toast.show({
        icon: 'fail',
        content: "至少 8 个字符"
      })
    } else if (!gz.test(_passwordValue)) {
      Toast.show({
        icon: 'fail',
        content: "字母和数字的混合",
      })
    } else if (_passwordValue !== _confirmValue) {
      Toast.show({
        icon: 'fail',
        content: "两次密码输入需要保持一致",
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        await post("/app/account/resetPassword", {
          "old_password": _currentValue,
          "new_password": _passwordValue,
        })
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: "成功"
        })
        setTimeout(() => {
          history.goBack()
        }, 1000)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      } 
    }
  }
  
  return (
    <>
    {
      <div className="verify-account-page">
        <div className="nav-bar" >
          <img src={IMAGE.back_black} alt="" className="back-button" onClick={() => history.goBack()} />
        </div>
        <p className="title">修改密码</p>
        <p className="content">密码至少 8 个字符 — 字母和数字的混合</p>
        <p className="input-name">当前密码</p>
        <div className={`input-container ${onFocusCurrent ? 'input-container-active' : ''}`}>
          <Input clearable value={currentValue} type='password' onFocus={() => setOnFocusCurrent(true)} onBlur={() => setOnFocusCurrent(false)} onChange={val => { setCurrentValue(val) }} className="input-div" />
        </div>
        <p className="input-name">新密码</p>
        <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
          <Input clearable value={passwordValue} type='password' onFocus={() => setOnFocusPassword(true)} onBlur={() => setOnFocusPassword(false)} onChange={val => { setPasswordValue(val) }} className="input-div" />
        </div>
        <p className="input-name">确认密码</p>
        <div className={`input-container ${onFocusConfirm ? 'input-container-active' : ''}`}>
          <Input clearable value={confirmValue} type='password' onFocus={() => setOnFocusConfirm(true)} onBlur={() => setOnFocusConfirm(false)} onChange={val => { setConfirmValue(val) }} className="input-div" />
        </div>
        <Button className="send-button" onClick={() => next()}>确认</Button>
        {/* <p className='forget-button' onClick={() => history.replace("/forgetPassword")}>忘记密码？</p> */}
      </div>
    }
    </>
  )
}
export default withRouter(ChangePasswordPage)