import React from "react"
import { Modal, Button } from "antd-mobile"
import "./styles.less"

const IndexComponent = ({
  visible,
  setVisible,
  setCustomerVisible,
  onChange
}) => {

  return (
    <Modal 
      visible={visible} 
      bodyClassName="m_insufficient" 
      content={
        <div className="m_insufficient_div">
          <p className="m_insufficient_title">USDT 余额不足</p>
          <p className="m_insufficient_content">您的 USDT 余额低于15，建议先充值，或选择人工通道开卡</p>
          <div className="m_insufficient_buttons">
            <Button className="m_insufficient_button" style={{ "--color": "#212121" }} onClick={() => {
              setVisible(false)
              setCustomerVisible(true)
            }}>人工通道开卡</Button>
            <Button className="m_insufficient_button" style={{ "--color": "#1437FA" }} onClick={onChange}>充值USDT</Button>
          </div>
        </div>
      } 
    />
  )
}

export default IndexComponent