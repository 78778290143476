import React, {useState} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast } from 'antd-mobile'
import { post } from '@/service'
import { IMAGE } from "@/assets"
import './styles.less' 

const LoginPage = ({ history }) => {
  const [value, setValue] = useState("")
  const [value2, setValue2] = useState("")
  const [onFocus, setOnFocus] = useState(false)
  const [onFocus2, setOnFocus2] = useState(false)

  async function toCaptcha() {
    const inputValue = value.trim()
    const inputValue2 = value2.trim()
    if (!inputValue) {
      Toast.show({
        icon: 'fail',
        content: '请输入你的账号',
      })
    } else if (!inputValue2) {
      Toast.show({
        icon: 'fail',
        content: '请输入密码',
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("app/captcha/needCaptcha", {
          email: inputValue
        })
        Toast.clear()
        const {need} = resp
        const type = "TENCENT"
        if (need) {
          if (type === "TENCENT") {
            initTencentCaptcha(inputValue, inputValue2, type).show()
          } else {
            window.grecaptcha.ready(function() {
              // 6LdldPwZAAAAACfK_nyA9IJ42FBtiNojpqURLQl4
              window.grecaptcha.execute('6LcSBe8pAAAAADI0vzJo_nAt84cOiNGnrmdAuepT', {action: 'submit'}).then(function(token) {
                tologin({
                  email: inputValue,
                  password: inputValue2,
                  captchaType: type,
                  tencentRandstr: null,
                  tencentTicket: null,
                  googleResponse: token
                })
              })
            })
          }
        } else {
          tologin({
            email: inputValue,
            password: inputValue2,
            captchaType: type,
            tencentRandstr: null,
            tencentTicket: null,
            googleResponse: null
          })
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }

    function initTencentCaptcha(email, password, type) {
      return new window.TencentCaptcha('2038742797', function(res) {
        const {ticket, randstr} = res
        if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
          tologin({
            email: email,
            password: password,
            captchaType: type,
            tencentRandstr: randstr,
            tencentTicket: ticket,
            googleResponse: null
          }) 
        }
      })
    }

    async function tologin(data) {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("/app/account/login/email", data)
        Toast.clear()
        localStorage.setItem("token", resp.token)
        history.push("/")
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
  }

  return (
    <div className="login-page">
      <div className="nav-bar" >
        <img src={IMAGE.back_black} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <p className="title">登录</p>
      <p className="subtitle">邮箱</p>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        <Input value={value} onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
      </div>
      <p className="subtitle">密码</p>
      <div className={`input-container ${onFocus2 ? 'input-container-active' : ''}`}>
        <Input value={value2} onFocus={() => setOnFocus2(true)} onBlur={() => setOnFocus2(false)} type='password' onChange={val => { setValue2(val) }} className="input-div" />
      </div>
      <Button className="next-button" onClick={() => toCaptcha()}>登录</Button>
      <p className='forget-button' onClick={() => history.push("/forgetPassword")}>忘记密码？</p>
    </div>
  )
}
export default withRouter(LoginPage)