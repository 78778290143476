import React from "react"
import { useHistory } from "react-router-dom"
import { IMAGE } from "@/assets"
import "./styles.less"

const tbs = [{
  image: 'card',
  name: '卡',
  go: '/'
},{
  image: 'assets',
  name: '资产',
  go: '/token/USDT'
},{
  image: 'invition',
  name: '邀请',
  go: '/invition'
},{
  image: 'profile',
  name: '我的',
  go: '/mine'
}]

const IndexComponent = (props) => {
  const { selIndex } = props
  const history = useHistory()

  return (
    <div className="m_tabbar">
    {
      tbs.map((item, index) => {
        return <div className="m_tabbar_item" key={index} onClick={() => history.push(item.go)}>
          <img src={IMAGE[index === selIndex ? `${item.image}Sel` : item.image]} alt="" />
          <p style={{ color: index === selIndex ? '#1437FA' : '#262626' }}>{ item.name }</p>
        </div>
      })
    }
    </div>
  )
}

export default IndexComponent