import React from "react"
import { Popup } from "antd-mobile"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({
  visible,
  setVisible
}) => {

  return (
    <Popup
      visible={visible}
      onMaskClick={() => setVisible(false)}
      bodyClassName="m_customer"
    >
      <img src={IMAGE.close_black} alt="" className="m_customer_close" onClick={() => setVisible(false)} />
      <p className="m_customer_title">开卡</p>
      <p className="m_customer_content">添加客服微信指导开卡操作</p>
      <img src={IMAGE.customerCode} alt="" className="m_customer_code" />
      <p className="m_customer_t">扫一扫上面的二维码图案，加我为朋友</p>
    </Popup>
  )
}

export default IndexComponent