import React from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Toast } from "antd-mobile"
import "./styles.less"

const IndexPage = ({ history }) => {
  const data = history.location.state.info

  return (
    <div className="p_cardinfo">
      <div className="p_cardinfo_navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <div className="p_cardinfo_title">
        卡信息
        {
          !!data && <CopyToClipboard text={`支付平台：${data.paymentPlatform}\n卡号:${data.number}\n有效期限:${data.expiration}\nCVC:${data.cvv}\n持卡人姓名:${data.name}\n国家:${data.country}\n账单地址:${data.residentialAddress}\n城市:${data.city}\n州:${data.state}\n邮政编码:${data.zipCode}`} onCopy={() => Toast.show({
            icon: "success",
            content: "复制成功",
          })}>
            <p className="p_cardinfo_copy">复制全部信息</p>
          </CopyToClipboard>
        }
      </div>
      {
        !!data && <>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">支付平台</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.paymentPlatform }</p>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">卡号</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.number }</p>
              <CopyToClipboard text={ data.number } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">有效期限</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.expiration }</p>
              <CopyToClipboard text={ data.expiration } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">CVC</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.cvv }</p>
              <CopyToClipboard text={ data.cvv } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">持卡人姓名</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.name }</p>
              <CopyToClipboard text={ data.name } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">国家</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.country }</p>
              <CopyToClipboard text={ data.country } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">账单地址</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.residentialAddress }</p>
              <CopyToClipboard text={ data.residentialAddress } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">城市</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.city }</p>
              <CopyToClipboard text={ data.city } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">州</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.state }</p>
              <CopyToClipboard text={ data.state } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="p_cardinfo_session">
            <p className="p_cardinfo_session_key">邮政编码</p>
            <div className="p_cardinfo_session_row">
              <p>{ data.zipCode }</p>
              <CopyToClipboard text={ data.zipCode } onCopy={() => Toast.show({
                icon: "success",
                content: "复制成功",
              })}>
                <img src={IMAGE.copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
        </>
      }
    </div>
  )
}
export default withRouter(IndexPage)